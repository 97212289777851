.lims-accessioning-page .plate-section {
    margin-top: 20px;

    .search-plate-section {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
}

.solana-plate-table {
    table {
        border-collapse: separate;
        margin-top: 10px;
        text-align: center;

        tr {
            cursor: pointer;

            td {
                padding: 2px;
                border: 1px solid black;
                border-radius: 10px;
                width: 300px;

                &.assigned-cell {
                    background-color: #cccccc;
                }

                &.current-cell-regular {
                    border: solid 3px rgb(3 105 179);
                    background-color: rgb(3 105 179);
                }

                &.current-cell-vip {
                    border: solid 3px rgb(238 52 78);
                    background-color: rgb(238 52 78);
                }
            }
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
    }
}

.plate-table {
    table {
        border-collapse: separate;
        margin-top: 10px;
        text-align: center;

        td {
            &.cell {
                height: 32px;
                width: 32px;
                border: solid 1px black;
                border-radius: 50%;
                cursor: pointer;
            }

            &.constant {
                height: 32px;
                width: 32px;
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;
            }

            &.assigned-cell {
                background-color: #a1a1a1;
                color: white;
                font-size: 14px;
            }

            &.current-cell-regular {
                border: solid 3px rgb(3 105 179);
                background-color: rgb(3 105 179);
            }

            &.current-cell-vip {
                border: solid 3px rgb(238 52 78);
                background-color: rgb(238 52 78);
            }
        }
    }
}

.plate-table.big-cell {
    table {
        td.cell {
            height: 50px;
            width: 50px;
        }

        td.constant {
            height: 50px;
            width: 50px;
            font-size: 25px;
        }

        td.assigned-cell {
            font-size: 18px;
        }
    }
}

.plate-table.small-cell {
    table {
        td.cell {
            height: 36px;
            width: 36px;
        }

        td.constant {
            height: 36px;
            width: 36px;
            font-size: 20px;
        }

        td.assigned-cell {
            font-size: 14px;
        }
    }
}

.plate-section {
    .regular-plate .plate-table {
        margin-top: 5px;
        border: 3px solid rgb(3 105 179);
        border-radius: 15px;
    }

    .vip-plate .plate-table {
        margin-top: 5px;
        border: 3px solid rgb(238 52 78);
        border-radius: 15px;
    }
}

.assign-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.text-bold {
    font-weight: bold;
}

.plate-template-box {
    border: 3px solid transparent;
    border-radius: 10px;

    &.active {
        border: 3px solid #ee344e;
    }
}

.sidebar-icon {
    position: absolute;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border: 1px solid #d3d3d3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 9;
    background: white;
}